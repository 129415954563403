import { UpdateOrder } from '../types/api/updateOrder';
import { Assignment } from '../types/assignment';
import { AssignmentStatus } from '../types/enums/assignmentStatus';
import { AssignmentType } from '../types/enums/assignmentType';
import { OrderStatus } from '../types/enums/orderStatus';
import { OrderType } from '../types/enums/orderType';
import { VehicleType } from '../types/enums/vehicleType';
import { Order } from '../types/order';

export const translateOrderField = (field: keyof UpdateOrder) => {
  switch (field) {
    case 'comment': return 'Kommentar';
    case 'date': return 'Dato';
    case 'fromProject': return 'Fra Prosjekt';
    case 'toProject': return 'Til Prosjekt';
    case 'contactPersonEmployeeNumber': return 'Kontaktperson';
    case 'contactPersonName': return 'Kontaktperson Navn';
    case 'contactPersonPhoneNumber': return 'Kontaktperson Telefon';
    case 'earlyDelivery': return 'Tidlig Levering';
    case 'extraTimeInFacility': return 'Ekstra Tid i Anlegget';
    case 'importantDeadline': return 'Viktig Frist';
    case 'loads': return 'Last';
    case 'numberOfVehicles': return 'Anntall Kjøretøy';
    case 'type': return 'Type';
    case 'vehicle': return 'Kjøretøy';
    case 'when': return 'Leveringstidspunkt';
    case 'gateCode': return 'Kode for port';
    case 'extraEquipment': return 'Ekstra utstyr';
    case 'hasDispensation': return 'Dispensasjon';
    case 'daytimeDelivery': return 'Kan tas på dagtid';
    default: return 'Ukjent';
  }
};

export const vehicleType = (vehicle: VehicleType) => {
  switch (vehicle) {
    case 'Single':
      return 'Enkel';
    case 'Double':
      return 'Dobbel';
    case 'Semi':
      return 'Semi';
    case 'CarWithTrailer':
      return 'Bil med henger';
    case 'SixxSix':
      return '6x6';
    case 'HookLift':
      return 'Kroklift';
    default:
      return vehicle;
  }
};

export const translateVehicleType = (order: Order) => vehicleType(order.vehicle);

export const translateMassType = (order: Order | undefined) => {
  switch (order?.type) {
    case 'MassIn':
      return 'Masse inn';
    case 'MassOut':
      return 'Masse ut';
    case 'MassInternal':
      return 'Internkjøring';
    case 'ContainerDeliver':
      return 'Container Ut';
    case 'ContainerCollect':
      return 'Container Hent';
    case 'ContainerEmptying':
      return 'Container tøm';
    case 'HaulHook':
      return 'Slep Krok';
    case 'HaulSemi':
      return 'Slep Semi';
    default:
      return 'Utilgjengelig';
  }
};

export const translateUnavailabilityType = (assignment: Assignment | undefined) => {
  if (assignment?.status === 'Unavailable') return 'Utilgjengelig';
  if (assignment?.status !== 'VehicleService') return 'Utilgjengelig';
  return assignment.orderComment;
};

export const translateTimeframe = (order: Order | undefined) => {
  if (!order) return '';
  switch (order.when.when) {
    case 'Morning':
      return 'Morgen';
    case 'BeforeFood':
      return 'Før mat';
    case 'AfterFood':
      return 'Etter mat';
    case 'Custom':
      return 'Egendefinert';
    case 'DuringTheDay':
    default:
      return 'I løpet av dag';
  }
};

export const getOrderStatusType = (currStatus: OrderStatus | undefined) => {
  switch (currStatus) {
    case 'Created':
      return 'Opprettet';
    case 'UnderPlanning':
      return 'Under planlegging';
    case 'Planned':
      return 'Planlagt';
    case 'Completed':
      return 'Fullført';
    case 'Cancelled':
      return 'Kansellert';
    case 'Undelivered':
      return 'Ulevert';
    case 'Declined':
      return 'Avslått';
    default:
      return currStatus;
  }
};

export const getAssignmentStatusType = (currStatus: AssignmentStatus | undefined) => {
  switch (currStatus) {
    case 'UnderPlanning':
      return 'Under planlegging';
    case 'Approved':
      return 'Godkjent';
    case 'Started':
      return 'Pågående';
    case 'Completed':
      return 'Fullført';
    case 'Cancelled':
      return 'Kansellert';
    case 'NotDelivered':
      return 'Ikke levert';
    case 'VerifiedNotDelivered':
      return 'Ikke levert (bekreftet)';
    case 'Deleted':
      return 'Slettet';
    case 'Unavailable':
      return 'Utilgjengelig';
    case 'VehicleService':
      return 'PKK/Service';
    default:
      return currStatus;
  }
};

export const translateAssignmentStatusDriver = (status: AssignmentStatus, serviceType?: string) => {
  if (status === 'Approved') return 'Mottatt';
  if (status === 'Started') return 'Startet';
  if (status === 'VehicleService' && serviceType) return serviceType;
  return getAssignmentStatusType(status);
};

export const translateErrorCode = (errorText: string, errorCode = 500) => {
  if (errorText.includes('authentication')) {
    return 'Et autoriseringsproblem har oppstått';
  }
  if (errorCode === 403) {
    return 'En autoriseringsfeil har oppstått';
  }
  if (errorText.includes('Not allowed to change FromTime')) {
    return 'Kan ikke endre start tid på dette oppdraget';
  }
  if (errorText.includes('Not allowed to change vehicle with status Approved')) {
    return 'Kan ikke endre kjøretøy på et godkjent oppdrag';
  }
  if (errorText.includes('ArgumentException')) {
    return 'En argumentfeil har oppstått, kunne ikke fullføre innsending';
  }
  if (errorText.includes('ArgumentNullException')) {
    return 'Ett eller flere argumenter mangler, kunne ikke fullføre innsending';
  }
  if (errorText.includes('Max 10m3 per load allowed when split load.')) {
    return 'Feil under oppretting. Maks 10m3 per last lov når last er splittet';
  }
  if (errorText.includes('Registration number already exists')) {
    return 'En bil med dette registreringsnummeret finnes allerede';
  }
  if (errorText.includes('Cannot delete vehicle with assignments')) {
    return 'Kan ikke slette innleiebil med oppdrag';
  }
  if (errorText.includes('Substitute already exists at this time')) {
    return 'En vikar finnes allerede på denne tiden';
  }
  return 'En feil har oppstått';
};

export const getTransportType = (id: OrderType|AssignmentType|undefined) => {
  switch (id) {
    case 'MassIn':
      return 'Masse Inn';
    case 'MassOut':
      return 'Masse Ut';
    case 'MassInternal':
      return 'Internkjøring';
    case 'ContainerDeliver':
      return 'Container Ut';
    case 'ContainerCollect':
      return 'Container Hent';
    case 'ContainerEmptying':
      return 'Container Tøm';
    case 'ContainerSwap':
      return 'Container Bytt';
    case 'HaulHook':
      return 'Maskinslep Krok';
    case 'HaulSemi':
      return 'Maskinslep Semi';
    case 'Diesel':
      return 'Diesel';
    default:
      return 'Ukjent';
  }
};
