import { FC, ReactNode } from 'react';
import {
  IconButton,
  MenuItem,
  Skeleton,
  Typography,
  Divider,
  Button,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import BuildIcon from '@mui/icons-material/Build';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { VehicleAction } from '../../../core/types/vehicle';
import { PopMenu } from '../../../components/PopMenu';
import { CallLink } from '../../../components/CallLink';
import { SubstituteAssignment } from '../../../core/types/substituteAssignment';
import { RegistrationPlate } from '../../../components/RegistrationPlate';
import { ExpiredCert } from '../../../shared/components/certification/expiredCert';

interface Contact {
  id?: number,
  fullName: string,
  mobilePhoneWork: string,
  expiredCerts?: number,
}

const DriverButton = ({
  children,
  clickable = false,
  onClick = () => null,
}: {
  children: ReactNode,
  clickable?: boolean
  onClick?: () => void,
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!clickable) return <>{children}</>;
  return (
    <Button
      sx={{
        p: 0,
        m: 0,
        border: 'inherit',
        color: 'inherit',
        fontWeight: 'inherit',
        textTransform: 'none',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        letterSpacing: 'inherit',
        alignItems: 'flex-start',
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const VehicleItem: FC<{
  type?: string,
  registration?: string,
  internalNumber?: string,
  driver?: Contact,
  substitutes?: SubstituteAssignment[],
  loading?: boolean,
  isRental?: boolean,
  isDeleted?: boolean,
  onButtonPressed?: (action: VehicleAction, id: string, subId?: number) => void,
  onDriverPressed?: (driverId: number, name: string) => void,
  disabled?: boolean,
}> = ({
  type = '',
  registration = '',
  internalNumber,
  driver = null,
  substitutes = [],
  loading = false,
  isRental = false,
  isDeleted = false,
  onButtonPressed = () => null,
  onDriverPressed = () => null,
  disabled = false,
}) => {
  if (loading) {
    return (
      <div>
        <Skeleton variant="text" height={25} width="50%" />
        <Skeleton variant="text" width="75%" />
      </div>
    );
  }

  return (
    <div className="vehicle-item-wrapper">
      {!driver ? null : (
        <div className={substitutes.length <= 0 ? 'vehicle-line-wrapper flexparent bold-text' : 'vehicle-line-wrapper flexparent'}>
          <DriverButton
            onClick={() => driver.id && onDriverPressed(driver.id, driver.fullName)}
            clickable={!!driver.id}
          >
            <PersonIcon fontSize="small" />
            <span className="vehicle-driver-name shorten-name">
              {driver.fullName}
            </span>
            <ExpiredCert expCert={driver.expiredCerts ?? 0} />
          </DriverButton>
          {driver.fullName && driver.mobilePhoneWork ? <span className="flexchild" /> : null}
          <span className="vehicle-driver-number">
            <CallLink className="number-link" phoneNumber={driver.mobilePhoneWork} />
          </span>
        </div>
      )}
      {
        [...substitutes].sort((a, b) => a.start.localeCompare(b.start)).map((s) => (
          <div key={s.id} className="vehicle-line-wrapper flexparent bold-text inline-substitute">
            <DriverButton
              onClick={() => s.employeeNumber && onDriverPressed(s.employeeNumber, s.name ?? '')}
              clickable={!!s.employeeNumber}
            >
              <GroupIcon fontSize="small" />
              <span className="vehicle-driver-name shorten-name">
                {s.name}
              </span>
              <ExpiredCert expCert={s.driver?.expiredCertifications ?? 0} />
            </DriverButton>
            {s.name && s.phone ? <span className="flexchild" /> : null}
            <span className="vehicle-driver-number">
              <CallLink className="number-link" phoneNumber={s.phone} />
            </span>
          </div>
        ))
      }
      <div className="vehicle-line-wrapper flexparent right-aligned-vehicle">
        <span>
          {!registration ? null : <RegistrationPlate number={registration} /> }
          {internalNumber && (

          <PopMenu
            disabled={disabled}
            side="left"
            renderButton={(
              <IconButton onClick={() => null}>
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
            )}
          >
            <MenuItem onClick={() => onButtonPressed('createUnavailability', internalNumber)}>
              <EventBusyIcon fontSize="small" color="action" sx={{ mr: 1 }} />
              <Typography>
                Opprett utilgjengelighet
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => onButtonPressed('createVehicleService', internalNumber)}>
              <BuildIcon fontSize="small" color="action" sx={{ mr: 1 }} />
              <Typography>
                Opprett service/PKK
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => onButtonPressed('unavailabilityView', internalNumber)}>
              <EventBusyIcon fontSize="small" color="action" sx={{ mr: 1 }} />
              <Typography>
                Utilgjengelighet
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => onButtonPressed('editRegion', internalNumber)}>
              <MapIcon fontSize="small" color="action" sx={{ mr: 1 }} />
              <Typography>
                Endre region
              </Typography>
            </MenuItem>
            {!isDeleted && (isRental && internalNumber ? (
              [
                <Divider key={1} />,
                (
                  <MenuItem key={2} onClick={() => onButtonPressed('edit', internalNumber)} sx={{ marginBottom: '2px' }}>
                    <DepartureBoardIcon fontSize="small" color="action" sx={{ mr: 1 }} />
                    <Typography>
                      Rediger innleiebil
                    </Typography>
                  </MenuItem>
                ),
              ]
            ) : (
              [
                <Divider key={3} />,
                (
                  <span key={4}>
                    <MenuItem onClick={() => onButtonPressed('editSubstitute', internalNumber, substitutes[0]?.id)} sx={{ marginBottom: '2px' }}>
                      <GroupAddIcon fontSize="small" color="action" sx={{ mr: 1 }} />
                      <Typography>
                        {!substitutes.length ? 'Opprett vikar' : 'Endre vikar'}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => onButtonPressed('substituteView', internalNumber)} sx={{ marginBottom: '2px' }}>
                      <PeopleAltIcon fontSize="small" color="action" sx={{ mr: 1 }} />
                      <Typography>
                        Vikarer
                      </Typography>
                    </MenuItem>
                  </span>
                ),
              ]
            ))}
          </PopMenu>
          )}
        </span>
        <span className="vehicle-font">{type}</span>
      </div>
    </div>
  );
};
