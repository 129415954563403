import { FC } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SecurityTraining } from '../../types/securityTraining';

export const SecurityTrainings: FC<{
    secs: SecurityTraining[]|undefined,
    isLoading: boolean,
  }> = ({
    secs,
    isLoading = false,
  }) => {
    if (!secs || isLoading) return null;

    return (
      <div>
        <Box mb={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            Tilgjengelige kurs i Didac
          </Typography>
          <Typography>
            - Kurs skal tas før aktuelt utstyr brukes, dette er et krav fra Forskrift om utførelse av
            arbeid.
          </Typography>
          <Typography> - Logg inn i Didac og søk etter aktuelt kurs i «Finn opplæring».</Typography>
          <Typography>
            - På mye av utstyret er det en QR-kode som tar deg rett til det aktuelle kurset i Didac.
          </Typography>
          <Typography>
            - I tillegg til Didac-kurset må det også gis praktisk opplæring i bruk av utstyret.
          </Typography>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Kurs</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Dato</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...secs].sort((a, b) => a.name.localeCompare(b.name)).map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="td" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell scope="row">{row.date}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
